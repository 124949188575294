import React from "react";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import CommonPage from "@icarius-common/commonPage";
import useHistoricalPeriods from "./useHistoricalPeriods";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";

const HistoricalPeriods = ({ code, name, handleGoBack }) => {

  const {
    isLoading,
    periods,
    gridData,
    gridType,
    gridTypes,
    period,
    gridColumnDef,
    setGridType,
    handleChangePeriod,
  } = useHistoricalPeriods(code);

  const goBackButton = () => (
    <CustomIconButton
      title={'Volver'}
      type={'goBack'}
      onClick={handleGoBack}
    />
  )

  const selectInput = () => (
    <>
      <div style={{ minWidth: 220, maxWidth: 220 }}>
        <FormControl fullWidth style={{ width: "100%" }}>
          <InputLabel id={`label-type`}>{"Tipo de proceso"}</InputLabel>
          <Select
            value={gridType}
            labelId={`label-type`}
            id={`select-type`}
            onChange={(e) => setGridType(e.target.value)}
            margin={"none"}
          >
            {
              gridTypes?.map(item => (
                <MenuItem
                  className={"whiteText"}
                  key={item.key}
                  value={item.key}>
                  {item.value}
                </MenuItem>
              ))
            }
          </Select>
        </FormControl>
      </div>
      <div style={{ minWidth: 220, maxWidth: 220, marginLeft: 10 }}>
        <FormControl fullWidth style={{ width: "100%" }}>
          <InputLabel id={`label-period`}>{"Período"}</InputLabel>
          <Select
            value={period}
            labelId={`label-period`}
            id={`select-period`}
            onChange={(e) => handleChangePeriod(e.target.value)}
            margin={"none"}
          >
            {
              periods?.map(item => (
                <MenuItem
                  className={"whiteText"}
                  key={item.key}
                  value={item.key}>
                  {item.value}
                </MenuItem>
              ))
            }
          </Select>
        </FormControl>
      </div>
    </>
  )

  return (
    <CommonPage
      isLoading={isLoading}
      title={`Históricos - ${name}`}
      gridTitle={`Históricos - ${name}`}
      ownColumnDef={gridColumnDef}
      rowData={gridData}
      menuItems={[goBackButton]}
      customHeader={selectInput}
      hasExpand
      hasHelp
      key={gridType} //para que al cambiar de gridType se regenere bien la grilla
    />
  )
}

export default HistoricalPeriods;