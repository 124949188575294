import * as actionTypes from "./actionTypes";
import {
  getHistoricalPeriodsApi,
  getHistoricalPeriodsByPeriodApi,
} from "@icarius-connection/api";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { getLocalizedErrorString } from "@icarius-localization/strings";

export const getHistoricalPeriodsAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET_INITIAL });
  try {
    let response = await getHistoricalPeriodsApi();

    let periods = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_INITIAL_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_INITIAL_FULFILLED,
      payload: { periods },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_INITIAL_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const getHistoricalPeriodsByPeriodAction = (period, code) => async (dispatch) => {
  dispatch({ type: actionTypes.GET });
  try {
    let response = await getHistoricalPeriodsByPeriodApi(`?period=${period}&code=${code}`);

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_FULFILLED,
      payload: { ...data },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};