import React from "react";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import paths from "@icarius-localization/paths";
import CommonPage from "@icarius-common/commonPage";
import { getLocalizedString } from "@icarius-localization/strings";
import useHistoricAccumulators from "./useHistoricAccumulators";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import CreateEditDialog from "./dialogs/createEditDialog";
import UploadFileDialog from "./dialogs/uploadFileDialog";
import StyledHelpMenu from "@icarius-common/styledMenu";
import MenuItemWithIcon from "@icarius-common/MenuItemWithIcon";
import useTemplates from "@icarius-common/templates/components/useTemplates";
import CreateTemplateDialog from "@icarius-common/templates/components/createTemplateDialog";
import TemplatesHeader from "@icarius-common/templates/components/templatesHeader";

const HistoricAccumulators = () => {
  const {
    data,
    employees,
    accumulators,
    periodArray,
    isLoading,
    state,
    handlers,
  } = useHistoricAccumulators();

  const {
    user,
    templates,
    selectedTemplate,
    isLoadingTemplates,
    createTemplateDialogIsOpen,
    getRole,
    handleCreateTemplate,
    handleImportTemplates,
    handleExportTemplates,
    handleSelectTemplateAndUpdateGrid,
    handleOpenCreateTemplateDialog,
    handleOpenUpdateTemplateDialog,
    handleOpenDeleteTemplateDialog,
    handleCloseCreateTemplateDialog,
  } = useTemplates('HISTORIC_ACCUMULATORS');

  const createButton = () => (
    <CustomIconButton
      title={getLocalizedString("create")}
      onClick={() => handlers.handleOpenCreateDialog()}
      type={"add"}
    />
  )

  const modifyButton = (gridRef) => (
    <CustomIconButton
      title={getLocalizedString("edit")}
      onClick={() => handlers.handleOpenEditDialog(gridRef)}
      type={"edit"}
    />
  )

  const selectInput = (gridRef) => (
    <>
      <div style={{ minWidth: 330, maxWidth: 330 }}>
        <FormControl fullWidth style={{ width: "100%" }}>
          <InputLabel id={`label-period`}>{"Seleccione período de consulta"}</InputLabel>
          <Select
            value={state.period}
            renderValue={(value) => value.name}
            labelId={`label-period`}
            id={`select-period`}
            onChange={(e) => handlers.handleFilterByPeriod(e.target.value)}
            margin={"none"}
          >
            {
              periodArray.map(item => (
                <MenuItem
                  className={"whiteText"}
                  key={item.key}
                  value={item.value}
                >
                  {item.value.name}
                </MenuItem>
              ))
            }
          </Select>
        </FormControl>
      </div>
      <div style={{ display: 'flex', paddingLeft: 10 }}>
        <TemplatesHeader
          gridRef={gridRef}
          user={user}
          templates={templates}
          selectedTemplate={selectedTemplate}
          getRole={getRole}
          handleImportTemplates={handleImportTemplates}
          handleExportTemplates={handleExportTemplates}
          handleOpenCreateTemplateDialog={handleOpenCreateTemplateDialog}
          handleOpenDeleteTemplateDialog={handleOpenDeleteTemplateDialog}
          handleOpenUpdateTemplateDialog={handleOpenUpdateTemplateDialog}
          handleSelectTemplateAndUpdateGrid={handleSelectTemplateAndUpdateGrid}
        />
      </div>
    </>
  )

  const child = (gridRef) =>
    <>
      {
        createTemplateDialogIsOpen &&
        <CreateTemplateDialog
          open={createTemplateDialogIsOpen}
          level={user.level}
          handleCreateTemplate={(role, name) => handleCreateTemplate(gridRef, role, name)}
          handleCloseDialog={handleCloseCreateTemplateDialog}
        />
      }
      {
        (state.createDialogIsOpen || state.editDialogIsOpen) &&
        <CreateEditDialog
          open={(state.createDialogIsOpen || state.editDialogIsOpen)}
          isLoading={isLoading}
          data={state.selectedDocument}
          handleClose={handlers.handleCloseCreateEditDialog}
          employees={employees}
          accumulators={accumulators}
          period={state.period}
        />
      }
      {
        state.uploadFileDialogIsOpen &&
        <UploadFileDialog
          open={state.uploadFileDialogIsOpen}
          reloadGrid={handlers.handleReloadGrid}
          isLoading={isLoading}
          handleClose={handlers.handleCloseUploadFileDialogIsOpen}
        />
      }
    </>

  const exportButton = (gridRef) =>
    <>
      <CustomIconButton
        title={"Exportar plantilla para importación"}
        onClick={(e) => handlers.handleSetMassiveMenuAnchor(e.currentTarget)}
        type={"download"}
      />
      <StyledHelpMenu
        anchorEl={state.massiveMenuAnchor}
        isOpen={Boolean(state.massiveMenuAnchor)}
        onClose={() => handlers.handleSetMassiveMenuAnchor(null)}
      >
        <MenuItemWithIcon
          text={'Exportar plantilla vacía'}
          onClick={() => handlers.handleDownloadStructure(gridRef, true)}
        />
        <MenuItemWithIcon
          text={'Exportar plantilla con datos'}
          onClick={() => handlers.handleDownloadStructure(gridRef, false)}
        />
      </StyledHelpMenu>
    </>

  const importButton = () =>
    <CustomIconButton
      title={"Importación de acumuladores"}
      onClick={handlers.handleOpenUploadFileDialogIsOpen}
      type={"upload"}
    />

  return (
    <CommonPage
      isLoading={isLoading || isLoadingTemplates}
      title={'Acumuladores históricos'}
      gridTitle={'Acumuladores históricos'}
      columnDefPage={paths.historicAccumulators}
      rowData={data}
      menuItems={[createButton, modifyButton, exportButton, importButton]}
      customHeader={selectInput}
      hasExpand
      hasHelp
      codeFieldName={'Código de empleado'}
    >
      {child}
    </CommonPage>
  )
}

export default HistoricAccumulators;