import paths from "@icarius-localization/paths";
import {
    numberComparatorMax2,
    numberFormatterMin2,
    numberFilterParams,
} from "@icarius-table/number";

export default [
    {
        pages: [paths.historicAccumulators],
        config: {
            headerName: 'Código de empleado',
            field: "employeeCode",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.historicAccumulators],
        config: {
            headerName: 'Apellidos y nombres',
            field: "employeeName",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.historicAccumulators],
        config: {
            headerName: 'Período',
            field: "period",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.historicAccumulators],
        config: {
            headerName: 'Código del acumulador',
            field: "accumulatorCode",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.historicAccumulators],
        config: {
            headerName: 'Nombre del acumulador',
            field: "accumulatorName",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.historicAccumulators],
        config: {
            headerName: 'Cantidad',
            field: "quantity",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.historicAccumulators],
        config: {
            headerName: 'Porcentaje',
            field: "percentage",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.historicAccumulators],
        config: {
            headerName: 'Importe',
            field: "amount",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
]