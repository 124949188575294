import React from "react";
import { useDispatch } from "react-redux";
import { TextField, Grid } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { createHealthDefinitionAction, modifyHealthDefinitionAction } from "../../actions";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import useHandleForm from "./useHandleForm";
import { useSelector } from "react-redux";
import { getCountry } from "src/app/selectors";

const gridStyle = { height: 75, padding: "0px 10px" };

const Form = (props) => {

  const {
    data,
    handleClose,
  } = props;
  const dispatch = useDispatch();
  const country = useSelector(getCountry);

  const create = (dataToSend) => {
    dispatch(createHealthDefinitionAction(dataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const modify = (dataToSend) => {
    dispatch(modifyHealthDefinitionAction(dataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const {
    isCreate,
    formData,
    setFormValue,
    submit
  } = useHandleForm(data, create, modify, openValidationError, country);

  return (
    <Grid container item xs={12} direction="row" style={{ paddingTop: 10 }}>
      <Grid container item xs={12}>
        <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
          <TextField
            disabled={!Boolean(isCreate)}
            required
            fullWidth
            margin={"none"}
            label={'Código'}
            onChange={(e) => setFormValue(e.target.value, "code")}
            value={formData.code}
            inputProps={{ maxLength: 8 }}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
          <TextField
            required
            fullWidth
            margin={"none"}
            label={'Nombre'}
            value={formData.name}
            onChange={(e) => setFormValue(e.target.value, "name")}
            inputProps={{ maxLength: 60 }}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
          <TextField
            fullWidth
            margin={"none"}
            label={'Código de institución'}
            value={formData.institutionCode}
            onChange={(e) => setFormValue(e.target.value, "institutionCode")}
            inputProps={{ maxLength: 12 }}
          />
        </Grid>
        {
          country === "CL" &&
          <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
            <TextField
              required
              fullWidth
              type='number'
              margin={"none"}
              label={'Código de Previred'}
              value={formData.previredCode}
              onChange={(e) => setFormValue(e.target.value, "previredCode")}
              inputProps={{ maxLength: 6 }}
            />
          </Grid>
        }
        {
          country === "CL" &&
          <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
            <TextField
              fullWidth
              type='number'
              margin={"none"}
              label={'Código LRE'}
              value={formData.lreCode}
              onChange={(e) => setFormValue(e.target.value, "lreCode")}
              inputProps={{ max: 999 }}
            />
          </Grid>
        }
      </Grid>
      <Grid container item xs={12} justify={"flex-end"} alignItems="center" style={{ paddingBottom: 20 }}>
        <ButtonDialogAction isAccept onClick={submit} text={getLocalizedString("save")} />
      </Grid>
    </Grid>
  );
}

export default Form;
