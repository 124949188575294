import NAME from "./constants";

export const RESET_STATE = NAME + "/RESET_STATE";

export const GET_INITIAL = NAME + "/GET_INITIAL";
export const GET_INITIAL_REJECTED = NAME + "/GET_INITIAL_REJECTED";
export const GET_INITIAL_FULFILLED = NAME + "/GET_INITIAL_FULFILLED";

export const GET = NAME + "/GET";
export const GET_REJECTED = NAME + "/GET_REJECTED";
export const GET_FULFILLED = NAME + "/GET_FULFILLED";