import * as actionTypes from "./actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { getCalculationProcessesAPI } from "@icarius-connection/api";

export const getHistoricalProcessesAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET_ROWS });
  try {
    let response = await getCalculationProcessesAPI("?status=approved");

    let rows = response.data && response.data.result;
    let templates = response.data && response.data.templates;
    let dateFormat = response.data && response.data.date_format;

    dispatch({
      type: actionTypes.GET_ROWS_FULFILLED,
      payload: { rows, dateFormat, templates }
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_ROWS_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

