import * as actionTypes from "./actionTypes";

const initialState = {
  periods: [],
  concepts: [],
  references: [],
  accumulators: [],
  isLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_INITIAL:
      return { ...state, isLoading: true };
    case actionTypes.GET_INITIAL_FULFILLED:
      return {
        ...state,
        isLoading: false,
        periods: action.payload.periods,
      };
    case actionTypes.GET_INITIAL_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.GET:
      return { ...state, isLoading: true };
    case actionTypes.GET_FULFILLED:
      return {
        ...state,
        isLoading: false,
        concepts: action.payload.concepts,
        references: action.payload.references,
        accumulators: action.payload.accumulators,
      };
    case actionTypes.GET_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.RESET_STATE:
      return initialState;
    default:
      return state;
  }
}
