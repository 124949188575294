import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getHistoricalProcessesAction } from "../actions";
import { getRows, getDateFormat, getLoading } from "../selectors";
import { getIsExportingGrid } from "src/app/selectors";
import { getLocalizedString } from "@icarius-localization/strings";
import CommonPage from "@icarius-common/commonPage";
import paths from "@icarius-localization/paths";
import useAccountingEntries from "@icarius-pages/currentProcesses/components/hooks/useAccountingEntries";
import AccountingEntriesDialog from "@icarius-pages/currentProcesses/components/dialogs/accountingEntriesDialog";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import AccountBalance from "@material-ui/icons/AccountBalance";

const HistoricalProcesses = ({ handleOpenEmployeesPage }) => {

  const dispatch = useDispatch();

  const data = useSelector(getRows);
  const dateFormat = useSelector(getDateFormat);
  const isLoading = useSelector(getLoading);
  const isExportinGrid = useSelector(getIsExportingGrid);

  useEffect(() => {
    dispatch(getHistoricalProcessesAction());
  }, [dispatch])

  const {
    accountingEntriesData,
    accountingEntriesFunctions,
  } = useAccountingEntries();

  const accountingEntriesItem = (gridRef) =>
    <CustomIconButton
      title={'Asientos contables'}
      onClick={() => accountingEntriesFunctions.handleOpenDialog(gridRef)}
    >
      <AccountBalance />
    </CustomIconButton>

  return (
    <CommonPage
      rowData={data}
      title={getLocalizedString("historicalProcessesTitle")}
      gridTitle={getLocalizedString("historicalProcessesTitle")}
      dateFormat={dateFormat}
      columnDefPage={paths.historicalProcesses}
      menuItems={[accountingEntriesItem]}
      isLoading={isExportinGrid || isLoading || accountingEntriesData.isLoading}
      handleRowClick={(row) => handleOpenEmployeesPage(row.data)}
      hasExpand
      hasHelp
      codeFieldName={'Código de proceso'}
    >
      {
        accountingEntriesData.dialogIsOpen &&
        <AccountingEntriesDialog
          open={accountingEntriesData.dialogIsOpen}
          data={accountingEntriesData.arrayData}
          handleClose={accountingEntriesFunctions.handleCloseDialog}
        />
      }
    </CommonPage>
  );
}

export default HistoricalProcesses;
