import * as actionTypes from "./actionTypes";

const initialState = {
  data: [],
  employees: [],
  accumulators: [],
  isLoading: false,
  templates: [],
  isLoadingTemplates: false,
  isLoadingMatch: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET:
      return { ...state, isLoading: true };
    case actionTypes.GET_FULFILLED:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
        employees: action.payload.employees,
        accumulators: action.payload.accumulators,
      };
    case actionTypes.GET_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.GET_MATCH:
      return { ...state, isLoadingMatch: true };
    case actionTypes.GET_MATCH_FULFILLED:
      return { ...state, isLoadingMatch: false };
    case actionTypes.GET_MATCH_REJECTED:
      return { ...state, isLoadingMatch: false };

    case actionTypes.CREATE:
      return { ...state, isLoading: true };
    case actionTypes.CREATE_FULFILLED:
      return { ...state, isLoading: false };
    case actionTypes.CREATE_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.MODIFY:
      return { ...state, isLoading: true };
    case actionTypes.MODIFY_FULFILLED:
      return { ...state, isLoading: false };
    case actionTypes.MODIFY_REJECTED:
      return { ...state, isLoading: false };
    
    case actionTypes.DOWNLOAD:
      return { ...state, isLoading: true };
    case actionTypes.DOWNLOAD_FULFILLED:
      return { ...state, isLoading: false };
    case actionTypes.DOWNLOAD_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.RESET_STATE:
      return initialState;
    default:
      return state;
  }
}
