import React, { useState } from "react";
import { useSelector } from "react-redux";
import HistoricalProcesses from "./historical";
import { EmployeesProcesses } from "@icarius-pages/employeesProcesses";
import { getTemplates } from "../selectors";

const HistoricalProcessesSwitch = () => {

  const [employeesIsOpen, setEmployeesIsOpen] = useState(false);
  const [process, setProcess] = useState(null);
  const templates = useSelector(getTemplates);

  const handleOpenEmployeesPage = (process) => {
    setProcess(process);
    setEmployeesIsOpen(true);
  }

  return (
    <>
      {
        employeesIsOpen ?
          <EmployeesProcesses
            process={process}
            templates={templates}
            handleGoBack={() => setEmployeesIsOpen(false)}
            hideDelete
          />
          :
          <HistoricalProcesses
            handleOpenEmployeesPage={handleOpenEmployeesPage}
          />
      }
    </>
  );
}

export default HistoricalProcessesSwitch;
