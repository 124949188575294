import { useState, useEffect } from "react";
import * as yup from 'yup';
import { createDateFromYYYYMM, formatDateYYYYMM } from "@icarius-utils/date";
import { useDispatch } from "react-redux";
import { getRowWithSameMainValues } from "@icarius-pages/historicAccumulators/actions";

const useHandleForm = (data, createCallback, modifyCallback, invalidDataCallback, employees) => {

  const isCreate = !Boolean(data);
  const dispatch = useDispatch();

  const createInitialData = () => {

    const getInitialFieldData = (fieldName) => {
      if (fieldName === 'period') {
        return isCreate ? new Date() : createDateFromYYYYMM(data[fieldName]);
      }

      if (fieldName === 'quantity' || fieldName === 'percentage' || fieldName === 'amount') {
        return isCreate ? "" : data[fieldName];
      }

      if (fieldName === 'employeeCode') {
        return isCreate ? { key: "", value: "" } : employees.find(item => item.key === data.employeeCode);
      }

      if (fieldName === 'accumulatorCode') {
        return isCreate ? "" : data.accumulatorCode;
      }

      return isCreate ? "" : data[fieldName];
    }

    const fieldNames = [
      'employeeCode',
      'period',
      'accumulatorCode',
      'quantity',
      'percentage',
      'amount',
    ];

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(createInitialData());
  const [rowWithSameMainValues, setRowWithSameMainValues] = useState();

  useEffect(() => {
    if (isCreate && formData.employeeCode && formData.period && formData.accumulatorCode) {
      dispatch(getRowWithSameMainValues(formData.employeeCode.key, formatDateYYYYMM(formData.period), formData.accumulatorCode))
        .then(resp => {
          if (resp?.data?.data) {
            setRowWithSameMainValues(resp.data.data);
            setFormData(prev => ({
              ...prev,
              'quantity': resp.data.data.quantity,
              'percentage': resp.data.data.percentage,
              'amount': resp.data.data.amount,
            }))
          } else {
            setRowWithSameMainValues();
            setFormData(prev => ({
              ...prev,
              'quantity': "",
              'percentage': "",
              'amount': '',
            }))
          }
        })
    }
  }, [dispatch, isCreate, formData.employeeCode, formData.period, formData.accumulatorCode])

  const dataIsValid = async () => {
    const schema = yup.object().shape({
      'employeeCode': yup.object().required(),
      'period': yup.string().required(),
      'accumulatorCode': yup.string().required(),
      'quantity': yup.number().nullable(true).transform((_, val) => val === Number(val) ? val : null),
      'percentage': yup.number().nullable(true).transform((_, val) => val === Number(val) ? val : null),
      'amount': yup.number().nullable(true).transform((_, val) => val === Number(val) ? val : null),
    });

    return await schema.isValid(formData).then((valid) => valid);
  }

  const submit = async () => {
    if (await dataIsValid()) {
      let dataToSend = {
        ...formData,
        employeeCode: formData.employeeCode.key,
        period: formatDateYYYYMM(formData.period),
      };

      (isCreate && !Boolean(rowWithSameMainValues)) ? createCallback(dataToSend) : modifyCallback(dataToSend);
      return true;
    } else {
      invalidDataCallback();
      return false;
    }
  }

  const setFormValue = (value, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: value,
    })
  }

  return { isCreate, formData, setFormValue, submit };
}

export default useHandleForm;
