import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getLoading,
  getData,
  getEmployees,
  getAccumulators
} from "../selectors";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import {
  getHistoricAccumulatorsAction,
  getSpreadsheetAction,
} from "../actions";
import moment from "moment";

const startAll = moment(new Date(0, 0, 1));
const endAll = moment(new Date(2200, 0, 1));

const startMonth = moment().month(moment().month()).startOf('month');
const endMonth = moment().month(moment().month()).endOf('month');

const startPrevMonth = moment().subtract(1, "month").startOf('month');
const endPrevMonth = moment().subtract(1, "month").endOf('month');

const startNextMonth = moment().add(1, "month").startOf('month');
const endNextMonth = moment().add(1, "month").endOf('month');

const startQuarter = moment().startOf('quarter');
const endQuarter = moment().endOf('quarter');

const startSemester = moment().month(moment().month() + 1 > 6 ? 6 : 0).startOf('month');
const endSemester = moment().month(moment().month() + 1 > 6 ? 11 : 5).endOf('month');

const startYear = moment().startOf('year');
const endYear = moment().endOf('year');

const startPrevQuarter = moment().subtract(1, 'Q').startOf('quarter');
const endPrevQuarter = moment().subtract(1, 'Q').endOf('quarter');

const startPrevSemester = moment().month(moment().month() + 1 > 6 ? 0 : 6).startOf('month');
const endPrevSemester = moment().month(moment().month() + 1 > 6 ? 5 : 11).endOf('month');

const startPrevYear = moment().subtract(1, 'Y').startOf('year');
const endPrevYear = moment().subtract(1, 'Y').endOf('year');

const start24Month = moment().subtract(24, 'M').startOf('month');
const end240Month = moment().subtract(1, 'M').endOf('month');

const start36Month = moment().subtract(36, 'M').startOf('month');
const end36Month = moment().subtract(1, 'M').endOf('month');


const periodArray = [
  {
    key: -1,
    value: {
      name: 'Todos',
      from: startAll,
      to: endAll,
    },
  },
  {
    key: 0,
    value: {
      name: `Mes actual (${startMonth.format("YYYY/MM")} al ${endMonth.format("YYYY/MM")})`,
      from: startMonth,
      to: endMonth,
    },
  },
  {
    key: 1,
    value: {
      name: `Mes anterior (${startPrevMonth.format("YYYY/MM")} al ${endPrevMonth.format("YYYY/MM")})`,
      from: startPrevMonth,
      to: endPrevMonth,
    }
  },
  {
    key: 2,
    value: {
      name: `Mes siguiente (${startNextMonth.format("YYYY/MM")} al ${endNextMonth.format("YYYY/MM")})`,
      from: startNextMonth,
      to: endNextMonth,
    }
  },
  {
    key: 3,
    value: {
      name: `Trimestre actual (${startQuarter.format("YYYY/MM")} al ${endQuarter.format("YYYY/MM")})`,
      from: startQuarter,
      to: endQuarter,
    }
  },
  {
    key: 4,
    value: {
      name: `Semestre actual (${startSemester.format("YYYY/MM")} al ${endSemester.format("YYYY/MM")})`,
      from: startSemester,
      to: endSemester,
    }
  },
  {
    key: 5,
    value: {
      name: `Año actual (${startYear.format("YYYY/MM")} al ${endYear.format("YYYY/MM")})`,
      from: startYear,
      to: endYear,
    }
  },
  {
    key: 6,
    value: {
      name: `Trimestre anterior (${startPrevQuarter.format("YYYY/MM")} al ${endPrevQuarter.format("YYYY/MM")})`,
      from: startPrevQuarter,
      to: endPrevQuarter,
    }
  },
  {
    key: 7,
    value: {
      name: `Semestre anterior (${startPrevSemester.format("YYYY/MM")} al ${endPrevSemester.format("YYYY/MM")})`,
      from: startPrevSemester,
      to: endPrevSemester,
    }
  },
  {
    key: 8,
    value: {
      name: `Año anterior (${startPrevYear.format("YYYY/MM")} al ${endPrevYear.format("YYYY/MM")})`,
      from: startPrevYear,
      to: endPrevYear,
    }
  },
  {
    key: 9,
    value: {
      name: `Últimos 24 meses (${start24Month.format("YYYY/MM")} al ${end240Month.format("YYYY/MM")})`,
      from: start24Month,
      to: end240Month,
    }
  },
  {
    key: 10,
    value: {
      name: `Últimos 36 meses (${start36Month.format("YYYY/MM")} al ${end36Month.format("YYYY/MM")})`,
      from: start36Month,
      to: end36Month,
    }
  },
]

const useHistoricAccumulators = () => {

  const [period, setPeriod] = useState(periodArray[1].value);
  const [editDialogIsOpen, setEditDialogIsOpen] = useState(false);
  const [createDialogIsOpen, setCreateDialogIsOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null); //seleccionado para editar
  const [massiveMenuAnchor, setMassiveMenuAnchor] = useState(null);
  const [uploadFileDialogIsOpen, setUploadFileDialogIsOpen] = useState(false);

  const dispatch = useDispatch();
  const data = useSelector(getData);
  const isLoading = useSelector(getLoading);
  const employees = useSelector(getEmployees);
  const accumulators = useSelector(getAccumulators);

  useEffect(() => {
    dispatch(getHistoricAccumulatorsAction(period.from, period.to));
  }, [dispatch, period])

  const handleSetMassiveMenuAnchor = data => {
    setMassiveMenuAnchor(data);
  }

  const handleOpenCreateDialog = () => {
    setSelectedDocument(null);
    setCreateDialogIsOpen(true);
  }

  const handleOpenEditDialog = (gridRef) => {
    let documents = gridRef.api.getSelectedRows();
    if (documents.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    setSelectedDocument(documents[0]);
    setEditDialogIsOpen(true);
  }

  const handleCloseCreateEditDialog = () => {
    setCreateDialogIsOpen(false);
    setEditDialogIsOpen(false);
    setSelectedDocument(null);
  }

  const handleFilterByPeriod = (newPeriod) => {
    setPeriod(newPeriod);
  }

  const handleDownloadStructure = (gridRef, emptyGrid) => {
    let rowsToInclude = [];
    if (!emptyGrid) {
      gridRef.api.forEachNodeAfterFilterAndSort(node => rowsToInclude.push(node.data));
      rowsToInclude = rowsToInclude.map(el => el.internalCode);
    }

    if (emptyGrid || rowsToInclude.length === 0) {
      dispatch(getSpreadsheetAction({ empty: true }));
    } else {
      if (rowsToInclude.length > 50000) {
        dispatch(openSnackbarAction({ msg: "Para exportar la plantilla con datos es necesario que la cantidad de registros, en cada exportación, no sea superior a 50.000", severity: "error" }));
      } else {
        dispatch(getSpreadsheetAction({ rowsToInclude, empty: false, from: period.from, to: period.to }));
      }
    }
    setMassiveMenuAnchor(null);
  }

  const handleOpenUploadFileDialogIsOpen = () => {
    setUploadFileDialogIsOpen(true);
  }

  const handleCloseUploadFileDialogIsOpen = () => {
    setUploadFileDialogIsOpen(false);
  }

  const handleReloadGrid = () => {
    dispatch(getHistoricAccumulatorsAction(period.from, period.to));
  }

  const state = {
    period,
    editDialogIsOpen,
    createDialogIsOpen,
    selectedDocument,
    massiveMenuAnchor,
    uploadFileDialogIsOpen,
  }

  const handlers = {
    handleOpenCreateDialog,
    handleOpenEditDialog,
    handleCloseCreateEditDialog,
    handleFilterByPeriod,
    handleDownloadStructure,
    handleSetMassiveMenuAnchor,
    handleOpenUploadFileDialogIsOpen,
    handleCloseUploadFileDialogIsOpen,
    handleReloadGrid,
  }

  return {
    data,
    employees,
    accumulators,
    periodArray,
    isLoading,
    state,
    handlers
  }
}

export default useHistoricAccumulators;