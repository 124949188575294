import * as actionTypes from "./actionTypes";
import {
  createHistoricAccumulatorsApi,
  updateHistoricAccumulatorsApi,
  getHistoricAccumulatorsApi,
  getMatchHistoricAccumulatorsApi,
  getSpreadsheetApi,
  importSpreadsheetApi,
} from "@icarius-connection/api";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { getLocalizedErrorString, getLocalizedString } from "@icarius-localization/strings";
import moment from "moment";
import { downloadBlob } from "@icarius-utils/download";
import { getFileExtension, getFileName } from "@icarius-utils/fileUpload";

const errorHandler = (e, dispatch) => {
  let errorString = "";
  switch (e.response?.data?.status) {
    case "INVALID_DATA":
    case "VALIDATION_ERROR":
      errorString = getLocalizedString("validationError");
      break;
    case "IN_USE":
      errorString = 'No es posible eliminar el acumulador porque está en uso';
      break;
    default:
      errorString = getErrorStringFromError(e);
      break;
  }
  dispatch(openSnackbarAction({ msg: errorString, severity: "error" }));
}

export const getHistoricAccumulatorsAction = (from, to) => async (dispatch) => {
  dispatch({ type: actionTypes.GET });
  try {
    const fromFormatted = moment(from).format("YYYY/MM/DD")
    const toFormatted = moment(to).format("YYYY/MM/DD")

    let response = await getHistoricAccumulatorsApi(`?from=${fromFormatted}&to=${toFormatted}`);

    let data = response.data && response.data.data;
    let employees = response.data && response.data.employees;
    let accumulators = response.data && response.data.accumulators;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_FULFILLED,
      payload: { data, employees, accumulators },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const getRowWithSameMainValues = (employeeCode, period, accumulatorCode) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_MATCH });
  try {
    let response = await getMatchHistoricAccumulatorsApi(`?employeeCode=${employeeCode}&period=${period}&accumulatorCode=${accumulatorCode}`);
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_MATCH_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_MATCH_FULFILLED,
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_MATCH_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const createHistoricAccumulatorsAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE });
  try {
    let response = await createHistoricAccumulatorsApi(dataToSend);
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.CREATE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.CREATE_FULFILLED,
    });

    dispatch(openSnackbarAction({ msg: 'Acumulador histórico creado con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.CREATE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const updateHistoricAccumulatorsAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.MODIFY });
  try {
    let response = await updateHistoricAccumulatorsApi(dataToSend);
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.MODIFY_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.MODIFY_FULFILLED,
    });

    dispatch(openSnackbarAction({ msg: 'Acumulador histórico actualizado con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.MODIFY_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const getSpreadsheetAction = dataToSend => async (dispatch) => {
  dispatch({ type: actionTypes.DOWNLOAD });
  try {
    let response = await getSpreadsheetApi(dataToSend);
    let title = response.headers["x-content-namefile"];

    if (response.status !== 200) {
      dispatch({ type: actionTypes.DOWNLOAD_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DOWNLOAD_FULFILLED,
    });

    downloadBlob(response.data, getFileExtension(title), getFileName(title));

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DOWNLOAD_REJECTED });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const importSpreadsheetAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE });
  try {
    let formData = new FormData();
    formData.append('file', dataToSend.file);

    let response = await importSpreadsheetApi(formData);
    let title = response.headers["x-content-namefile"];
    let hasErrors = title && title.includes("Errores-");
    if (title) {
      title = title.replace("Errores-", "");
    }
    if (response.status !== 200) {
      dispatch({ type: actionTypes.CREATE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.CREATE_FULFILLED,
    });

    if (title) {
      downloadBlob(response.data, getFileExtension(title), getFileName(title));
      dispatch(openSnackbarAction({
        msg: 'Importación finalizada, verifique el archivo para ver el resultado',
        severity: !hasErrors ? "success" : "warning",
        duration: null
      }));
      return response;
    }
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.CREATE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};