import React from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import useHandleForm from "./useHandleForm";
import { createHistoricAccumulatorsAction, getHistoricAccumulatorsAction, updateHistoricAccumulatorsAction } from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import NumberFormatCustom from '@icarius-pages/myRequestsManager/components/modalRequests/numberFormatCustom';
import { getLoadingMatch } from "@icarius-pages/historicAccumulators/selectors";
import Loader from "@icarius-common/loader";
import Autocomplete from '@material-ui/lab/Autocomplete';

const gridStyle = { height: 75, padding: "0px 10px" };

const CreateEditDialog = (props) => {

  const { open, employees, accumulators, isLoading, data, handleClose, period } = props;

  const dispatch = useDispatch();
  const isLoadingMatch = useSelector(getLoadingMatch);

  const create = (dataToSend) => {
    dispatch(createHistoricAccumulatorsAction(dataToSend))
      .then((res) => {
        if (res?.status === 200) {
          dispatch(getHistoricAccumulatorsAction(period.from, period.to));
          handleClose();
        }
      });
  }

  const modify = (dataToSend) => {
    dispatch(updateHistoricAccumulatorsAction(dataToSend))
      .then((res) => {
        if (res?.status === 200) {
          dispatch(getHistoricAccumulatorsAction(period.from, period.to));
          handleClose();
        }
      });
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const { isCreate, formData, setFormValue, submit } = useHandleForm(data, create, modify, openValidationError, employees);

  return (
    <Dialog
      open={open}
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      maxWidth={"md"}
      fullWidth={true}
    >
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {isCreate ? "Crear acumulador histórico" : "Editar acumulador histórico"}
          <DialogTitleDivider />
        </DialogTitle>
        <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
        <DialogContent>
          <Loader open={isLoading || isLoadingMatch} />
          <Grid container item xs={12}>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <Autocomplete
                disabled={!isCreate}
                style={{ width: '100%' }}
                options={employees}
                value={formData.employeeCode}
                onChange={(event, newUser) => {
                  setFormValue(newUser, "employeeCode");
                }}
                clearOnBlur
                clearText={getLocalizedString("clear")}
                noOptionsText={''}
                getOptionLabel={(user) => user.value}
                renderInput={(params) => <TextField required {...params} label={'Empleado'} />}
              />              
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <DatePicker
                  disabled={!isCreate}
                  fullWidth
                  required
                  label={'Período'}
                  cancelLabel={getLocalizedString("cancel")}
                  okLabel={getLocalizedString("ok")}
                  invalidDateMessage=""
                  format="yyyy/MM"
                  margin="none"
                  value={formData.period}
                  onChange={(e) => setFormValue(e, 'period')}
                  views={["year", "month"]}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel required id={`label-accumulatorCode`}>{"Acumulador"}</InputLabel>
                <Select
                  disabled={!isCreate}
                  value={formData.accumulatorCode}
                  labelId={`label-accumulatorCode`}
                  id={`select-accumulatorCode`}
                  onChange={(e) => setFormValue(e.target.value, "accumulatorCode")}
                  margin={"none"}
                >
                  {
                    accumulators.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}
                      >
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <TextField
                fullWidth
                label={"Cantidad"}
                value={formData.quantity}
                onChange={(e) => setFormValue(e.target.value, 'quantity')}
                InputProps={{ inputComponent: NumberFormatCustom, min: "1", step: "0.1" }}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <TextField
                fullWidth
                label={"Porcentaje"}
                value={formData.percentage}
                onChange={(e) => setFormValue(e.target.value, 'percentage')}
                InputProps={{ inputComponent: NumberFormatCustom, min: "1", step: "0.1" }}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <TextField
                fullWidth
                label={"Importe"}
                value={formData.amount}
                onChange={(e) => setFormValue(e.target.value, 'amount')}
                InputProps={{ inputComponent: NumberFormatCustom, min: "1", step: "0.1" }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonDialogAction onClick={handleClose} text={getLocalizedString("cancel")} />
          <ButtonDialogAction onClick={submit} isAccept text={getLocalizedString("accept")} />
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default CreateEditDialog;
