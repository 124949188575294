import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	numberComparatorMax2,
	numberFormatterMin2,
	numberFilterParams,
} from "@icarius-table/number";
import {
  getLoading,
  getPeriods,
  getConcepts,
  getReferences,
  getAccumulators,
} from "../selectors";
import { RESET_STATE } from "../actionTypes";
import {
  getHistoricalPeriodsByPeriodAction,
  getHistoricalPeriodsAction,
} from "../actions";

const useHistoricalPeriods = (code) => {

  const dispatch = useDispatch();

  const [gridType, setGridType] = useState(0);
  const [period, setPeriod] = useState('');

  useEffect(() => {
    dispatch(getHistoricalPeriodsAction())
      .then(res => {
        if (res?.status === 200) {
          // presetear el primer periodo que venga
          setPeriod(res?.data?.data[0]?.key || "");
        }
      })
    return () => { dispatch({ type: RESET_STATE }) }
  }, [dispatch])

  useEffect(() => {
    if (period && code) {
      dispatch(getHistoricalPeriodsByPeriodAction(period, code));
    }
  }, [dispatch, period, code])

  const handleChangePeriod = (value) => {
    setPeriod(value);
  }

  const isLoading = useSelector(getLoading);
  const periods = useSelector(getPeriods);
  const concepts = useSelector(getConcepts);
  const references = useSelector(getReferences);
  const accumulators = useSelector(getAccumulators);

  const gridTypes = [
    {
      key: 0,
      value: 'Conceptos',
    },
    {
      key: 1,
      value: 'Referencias',
    },
    {
      key: 2,
      value: 'Acumuladores',
    },
  ];

  const gridData = useMemo(() => {
    const baseData = [concepts, references, accumulators][gridType];

    return baseData.map((row) => {
      const dataToReturn = {
        'Nombre': row['Nombre'],
        'Código': row['Código'],
      }

      if (gridType === 0) {
        dataToReturn['Familia'] = row['Familia'];
      }

      // agrego los dinámicos
      row.periods.forEach(item => dataToReturn[item.key] = item.value);

      return dataToReturn;
    });
  }, [concepts, references, accumulators, gridType]);

  const gridColumnDef = useMemo(() => {
    const baseData = [concepts, references, accumulators][gridType];
    let columnDefs = [];

    if (baseData?.length > 0) {
      // seteo los fijos
      columnDefs = [
        {
          headerName: 'Nombre',
          field: 'Nombre',
          filter: "agSetColumnFilter",
        },
        {
          headerName: 'Código',
          field: 'Código',
          filter: "agTextColumnFilter",
        }
      ];

      if (gridType === 0) {
        columnDefs.push({
          headerName: 'Familia',
          field: 'Familia',
          filter: "agSetColumnFilter",
        });
      }

      // a los period, setear el formateo
      baseData[0].periods.forEach((row) => {
        let item = {
          headerName: row.key,
          field: row.key,
          filter: "agTextColumnFilter",
        };

        // si no es tipo referencias (gridType 1), setear formateo de currency
        if (gridType !== 1) {
          item = {
            ...item,
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
          }
        }

        columnDefs.push(item);
      });
    }
    return columnDefs;
  }, [concepts, references, accumulators, gridType])

  return {
    isLoading,
    periods,
    gridData,
    gridType,
    gridTypes,
    period,
    gridColumnDef,
    setGridType,
    handleChangePeriod,
  }
}

export default useHistoricalPeriods;